import React, { useRef, useState } from 'react';
import intl from 'react-intl-universal';
import { Carousel } from 'antd';
import LazyLoad from 'react-lazyload';
import style from './index.module.less';

const Qualification: React.FC = () => {
    const file_cdn = '/oss-service';
    const [quaList] = useState([
        {
            id: '1',
            text: intl.get('HomePage.ITitle1'),
            imgUrl: '/static/home/qualification-img1.jpg'
        },
        {
            id: '2',
            text: intl.get('HomePage.ITitle2'),
            imgUrl: '/static/home/qualification-img2.jpg'
        },
        {
            id: '3',
            text: intl.get('HomePage.ITitle3'),
            imgUrl: '/static/home/qualification-img3.jpg'
        },
        {
            id: '4',
            text: intl.get('HomePage.ITitle4'),
            imgUrl: '/static/home/qualification-img4.jpg'
        }
    ]);
    const [actIndex, setActIndex] = useState<number>(0);
    const [actText, setActText] = useState(intl.get('HomePage.ITitle1'));
    const sliderRef = useRef<any>(null);
    return (
        <div className={style['qualification-container']}>
            <div className={style['title-box']}>
                <p className={style.title}>{intl.get('HomePage.Intelligence')}</p>
                <p className={style['tit-bg']}>Company Qualification</p>
                <p className={style['tit-desc']}>{intl.get('HomePage.IntelligenceDesc')}</p>
            </div>
            <div className={style['qua-content']}>
                <LazyLoad>
                    <div className={style['left-con']}>
                        <div className={style['qualification-img-con']}>
                            <div className={style['carousel-box']}>
                                <img
                                    loading="lazy"
                                    className={style['prev-img']}
                                    src={`${file_cdn}/static/home/prev-icon.png`}
                                    alt=""
                                    onClick={() => {
                                        sliderRef.current && sliderRef.current.prev();
                                    }}
                                />
                                <Carousel
                                    slidesToShow={1.5}
                                    ref={sliderRef}
                                    infinite
                                    beforeChange={(oldIdx, newIdex) => {
                                        const index = Math.ceil(newIdex);
                                        setActText(quaList[index].text);
                                        setActIndex(index);
                                    }}
                                    className={`${style['qualification-list']} qualification-list-container`}
                                    variableWidth
                                    dots={false}
                                >
                                    {quaList.map((item, index) => (
                                        <div key={item.id}>
                                            <div className={`${style['qualification-item']} ${actIndex === index ? style.act : ''}`}>
                                                <img loading="lazy" className={style['item-img']} src={`${file_cdn}${item.imgUrl}`} alt="" />
                                            </div>
                                        </div>
                                    ))}
                                </Carousel>
                                <img
                                    loading="lazy"
                                    src={`${file_cdn}/static/home/next-icon.png`}
                                    className={style['next-img']}
                                    alt=""
                                    onClick={() => {
                                        sliderRef.current && sliderRef.current.next();
                                    }}
                                />
                            </div>
                            <p className={style['item-text']}>{actText}</p>
                        </div>
                    </div>
                </LazyLoad>
            </div>
        </div>
    );
};

export default Qualification;
