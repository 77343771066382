import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { Carousel } from 'antd';
import LazyLoad from 'react-lazyload';
import { useRouter } from 'next/router';
import style from './index.module.less';

interface RegisterItem {
    id: string;
    imgUrl: string;
    imgCls?: string;
    cls?: string;
    icon?: string;
    title?: string;
    link?: string;
}

const MCaseShare: React.FC = () => {
    const file_cdn = '/oss-service';
    const router = useRouter();
    const [caseOneList] = useState([
        {
            id: 'c1',
            imgUrl: '/static/home/qualification-img1.jpg',
            cls: style['visa-icon'],
            icon: '/static/home/visa-icon1.png',
            title: intl.get('HomePage.CST1Children1'),
            link: '/services/hongkong'
        },
        {
            id: 'c2',
            imgUrl: '/static/home/qualification-img4.jpg',
            icon: '/static/home/visa-icon2.png',
            title: intl.get('HomePage.CST1Children2'),
            link: '/services/singapore'
        },
        {
            id: 'c3',
            cls: style['visa-icon'],
            imgUrl: '/static/home/qualification-img5.jpg',
            icon: '/static/home/visa-icon3.png',
            title: intl.get('HomePage.CST1Children3'),
            link: '/services/saintvincent'
        }
    ]);
    const [caseThreeList] = useState([
        {
            id: 'c7',
            imgUrl: '/static/home/visa-img-1.jpg',
            icon: '/static/home/nation-1.png',
            title: intl.get('HomePage.CST3Children1'),
            link: '/services/dubaiqz'
        },
        {
            id: 'c8',
            imgUrl: '/static/home/visa-img-2.jpg',
            icon: '/static/home/visa-icon1.png',
            title: intl.get('Hongkongym.Slogan'),
            link: '/services/turkeyym'
        },
        {
            id: 'c9',
            imgUrl: '/static/home/visa-img-3.jpg',
            icon: '/static/home/visa-icon1.png',
            title: intl.get('HomePage.CST3Children3'),
            link: '/services/vanuatuym'
        }
    ]);
    const [caseTwoList] = useState([
        {
            id: 'c4',
            imgCls: style['bank-img'],
            imgUrl: '/static/home/bank-img-1.jpg',
            link: '/services/bank'
        },
        {
            id: 'c5',
            imgCls: style['bank-img'],
            imgUrl: '/static/home/bank-img-2.jpg',
            link: '/services/bank'
        },
        {
            id: 'c6',
            imgCls: style['bank-img'],
            imgUrl: '/static/home/bank-img-3.jpg',
            link: '/services/bank'
        }
    ]);

    const listItemStyles = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '--item-box-act': `url('${file_cdn}/static/home/case-bg-act.svg')`,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '--item-box': `url('${file_cdn}/static/home/ash-icon.svg')`
    };
    const contetnRender = (params: { text: string; desc: string; topBg?: string }, list: RegisterItem[]) => {
        const { text, desc, topBg } = params;
        return (
            <LazyLoad>
                <div className={style['content-box']} style={listItemStyles as React.CSSProperties}>
                    <div className={style['tab-list']}>
                        <div className={`${style['tab-item']} ${style.act}`}>
                            <div className={style['item-box']}>
                                <div className={style['item-text']}>{text}</div>
                                <div className={style['item-line']} />
                            </div>
                        </div>
                    </div>
                    <div className={style['tab-content']}>
                        <div className={style['content-list']}>
                            <div className={style['content-item-list']}>
                                <div className={style['item-desc']} dangerouslySetInnerHTML={{ __html: desc }} />
                                {topBg && <img loading="lazy" className={style['top-bg']} src={`${file_cdn}${topBg}`} alt="" />}
                                <div className={style['content-child-list']}>
                                    <Carousel className="m-case-share-carousel">
                                        {list.map((item) => (
                                            <div key={item.id}>
                                                <div
                                                    className={`${style['child-item-img']} ${item.imgCls ? item.imgCls : ''}`}
                                                    onClick={() => {
                                                        router.push(item.link);
                                                    }}
                                                >
                                                    <img loading="lazy" src={`${file_cdn}${item.imgUrl}`} alt="" />
                                                    {item.icon && (
                                                        <div className={style['icon-box']}>
                                                            <div
                                                                className={`${style['child-icon']} ${item.cls ? item.cls : ''}`}
                                                                style={{ backgroundImage: `url(${file_cdn}${item.icon})` }}
                                                            />
                                                            <p className={style['child-tit']}>{item.title}</p>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </Carousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LazyLoad>
        );
    };
    return (
        <>
            <div className={style['case-share-container']}>
                <div className={style['case-title']}>
                    <div className={style['title-box']}>
                        <div className={style.tit}>{intl.get('HomePage.CaseSharing')}</div>
                        <div className={style['title-bg']}>Case Studies</div>
                    </div>
                    <div className={style['tit-desc']}>{intl.get('HomePage.CaseSharingDesc')}</div>
                </div>
                {contetnRender(
                    {
                        text: intl.get('HomePage.Niue'),
                        desc: intl.get('HomePage.CST1')
                    },
                    caseOneList
                )}
                {contetnRender(
                    {
                        text: intl.get('HomePage.AccountOpening'),
                        desc: intl.get('HomePage.CST2'),
                        topBg: '/static/home/bank-top-bg.png'
                    },
                    caseTwoList
                )}
                {contetnRender(
                    {
                        text: intl.get('HomePage.ImmigrationVisa'),
                        desc: intl.get('HomePage.CST3')
                    },
                    caseThreeList
                )}
            </div>
        </>
    );
};

export default MCaseShare;
