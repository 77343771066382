import classNames from 'classnames';
import intl from 'react-intl-universal';
import LazyLoad from 'react-lazyload';
import style from './index.module.less';

interface Iprops {
    list: { id: string; icon: string; tit: string; desc: string; noTitleMts?: boolean }[];
    isCenter?: boolean;
}

const Senior: React.FC<Iprops> = (props: Iprops) => {
    const file_cdn = '/oss-service';
    const { isCenter, list } = props;
    const itemBoxStyles = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '--box-nth-1': `#fdf2e9 url('${file_cdn}/static/home/senior-bg1.svg') no-repeat`,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '--box-nth-2': `#f1862a url('${file_cdn}/static/home/senior-bg2.svg') no-repeat`,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '--box-nth-3': `#fdf2e9 url('${file_cdn}/static/home/senior-bg3.svg') no-repeat`,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '--box-nth-4': `#f1862a url('${file_cdn}/static/home/senior-bg4.svg') no-repeat`
    };
    return (
        <div className={style['senior-container']} style={itemBoxStyles as React.CSSProperties}>
            <LazyLoad height={205}>
                <div className={style['senior-list-con']}>
                    <ul className={classNames(style['senior-list'], isCenter && style.center)}>
                        {list.map((item) => (
                            <li className={style['item-box']} key={item.id}>
                                <div className={style['icon-con']}>
                                    <img loading="lazy" src={`${file_cdn}${item.icon}`} alt="" />
                                </div>
                                <div className={style['text-con']}>
                                    <p>{item.noTitleMts ? item.tit : intl.get(item.tit)}</p>
                                    <p>{intl.get(item.desc) || item.desc}</p>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </LazyLoad>
        </div>
    );
};

Senior.defaultProps = {
    isCenter: false
};

export default Senior;
