import React, { useRef, useState } from 'react';
import intl from 'react-intl-universal';
import { Carousel } from 'antd';
import classNames from 'classnames';
import styles from './index.module.less';

const MHomeBanner: React.FC<any> = () => {
    const file_cdn = '/oss-service';
    const [list] = useState<any[]>([
        {
            id: '1',
            text: 'HomePage.OutreachSpecialist',
            desc: 'HomePage.OneStopService',
            tag: 'HomePage.MIntroduction1',
            img: '/static/home/m-home-banner.jpg'
        },
        {
            id: '2',
            text: 'HomePage.BannerTitle2',
            desc: '',
            tag: 'HomePage.BannerTag2',
            img: '/static/home/移动端_首页banner_全球银行开户.jpg'
        },
        {
            id: '3',
            text: 'HomePage.BannerTitle3',
            desc: '',
            tag: 'HomePage.BannerTag3',
            img: '/static/home/移动端-首页banner海_外移民签证.jpg'
        },
        {
            id: '4',
            text: 'HomePage.BannerTitle4',
            desc: '',
            tag: 'HomePage.BannerTag4',
            tag2: 'HomePage.BannerTag4-2',
            img: '/static/home/PC端_首页banner_海外公司注册.jpg'
        }
    ]);
    const [actIndex, setActIndex] = useState<number>(0);
    const bannerRef = useRef<any>(null);
    const bannerTextRef = useRef<any>(null);
    // const initFlag = useRef<boolean>(false);
    return (
        <div className={styles.contianer}>
            <Carousel
                autoplay
                asNavFor={bannerTextRef.current}
                dots={false}
                className={styles['banner-min']}
                ref={bannerRef}
                beforeChange={(index, index2) => {
                    setActIndex(index2);
                    bannerTextRef.current.goTo(index2);
                }}
            >
                {list.map((item) => (
                    <div key={item.id}>
                        <div
                            className={styles.banner}
                            style={{ background: `url(${file_cdn}${item.img}) no-repeat center`, backgroundSize: `cover` }}
                        />
                    </div>
                ))}
            </Carousel>
            <div className={styles['banner-decorate']}>
                <img src={`${file_cdn}/static/home/m-banner-decorate.png`} alt="" />
            </div>
            <div className={styles['mask-con']}>
                <div className={styles.mask}>
                    <div className={styles['']}>
                        <Carousel ref={bannerTextRef} dots={false} effect="fade" asNavFor={bannerRef.current} touchMove={false}>
                            {list.map((item) => (
                                <div key={item.id}>
                                    <div className={styles.title}>{item.text ? intl.get(item.text) : item.text}</div>
                                    <div className={styles.desc}>{item.desc ? intl.get(item.desc) : item.desc}</div>
                                    <div className={styles.tag} dangerouslySetInnerHTML={{ __html: item.tag ? intl.get(item.tag) : item.tag }} />
                                    {item.tag2 && <div className={classNames(styles.tag, styles.tag2)}>{intl.get(item.tag2)}</div>}
                                </div>
                            ))}
                        </Carousel>
                    </div>
                    {list.length > 1 && (
                        <div className={styles['doc-list']}>
                            {list.map((item, index) => (
                                <span
                                    key={item.id}
                                    className={actIndex === index ? styles.act : ''}
                                    onClick={() => {
                                        setActIndex(index);
                                        bannerRef.current && bannerRef.current.goTo(index);
                                        bannerTextRef.current && bannerTextRef.current.goTo(index);
                                    }}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MHomeBanner;
