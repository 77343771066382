import Layout from '@components/Common/Layout';
import HomePage from '@components/Home';

const Home = ({ isMobile }: any) => (
    <Layout isMobile={isMobile}>
        <HomePage isMobile={isMobile} />
    </Layout>
);

export default Home;
