import React, { useRef, useState } from 'react';
import intl from 'react-intl-universal';
import { RightOutlined } from '@ant-design/icons';
import { Carousel } from 'antd';
import LazyLoad from 'react-lazyload';
import { useRouter } from 'next/router';
import style from './index.module.less';

interface TabContentItem {
    id: string;
    text: string;
    topBg: string;
    children: Array<{
        id: string;
        imgUrl: string;
        imgCls?: string;
        cls?: string;
        icon: string;
        title: string;
        link: string;
    }>;
}

const CaseShare: React.FC = () => {
    const file_cdn = '/oss-service';
    const router = useRouter();
    const [tabContentList] = useState<TabContentItem[]>([
        {
            id: '1',
            text: intl.get('HomePage.CST1'),
            topBg: '/static/home/case-top-bg1.png',
            children: [
                {
                    id: 'c1',
                    imgUrl: '/static/home/qualification-img2.jpg',
                    imgCls: style['qualification-img'],
                    cls: style['visa-icon'],
                    icon: '/static/home/visa-icon1.png',
                    title: intl.get('HomePage.CST1Children1'),
                    link: '/services/hongkong'
                },
                {
                    id: 'c2',
                    imgUrl: '/static/home/qualification-img4.jpg',
                    imgCls: style['qualification-img'],
                    icon: '/static/home/visa-icon2.png',
                    title: intl.get('HomePage.CST1Children2'),
                    link: '/services/singapore'
                },
                {
                    id: 'c3',
                    cls: style['visa-icon'],
                    imgUrl: '/static/home/qualification-img5.jpg',
                    imgCls: style['qualification-img'],
                    icon: '/static/home/visa-icon3.png',
                    title: intl.get('HomePage.CST1Children3'),
                    link: '/services/saintvincent'
                }
            ]
        },
        {
            id: '2',
            text: intl.get('HomePage.CST2'),
            topBg: '/static/home/case-bank-bg.svg',
            children: [
                {
                    id: 'c4',
                    imgUrl: '/static/home/bank-img-1.jpg',
                    imgCls: style['bank-img'],
                    icon: '/static/home/bank-1.png',
                    title: intl.get('HomePage.CST2Children1'),
                    link: '/services/bank'
                },
                {
                    id: 'c5',
                    imgUrl: '/static/home/bank-img-2.jpg',
                    imgCls: style['bank-img'],
                    icon: '/static/home/bank-2.png',
                    title: intl.get('HomePage.CST2Children2'),
                    link: '/services/bank'
                },
                {
                    id: 'c6',
                    imgUrl: '/static/home/bank-img-3.jpg',
                    imgCls: style['bank-img'],
                    icon: '/static/home/bank-3.png',
                    title: intl.get('HomePage.CST2Children3'),
                    link: '/services/bank'
                }
            ]
        },
        {
            id: '3',
            text: intl.get('HomePage.CST3'),
            topBg: '',
            children: [
                {
                    id: 'c7',
                    imgUrl: '/static/home/visa-img-1.jpg',
                    icon: '/static/home/nation-1.png',
                    title: intl.get('HomePage.CST3Children1'),
                    link: '/services/dubaiqz'
                },
                {
                    id: 'c8',
                    imgUrl: '/static/home/visa-img-2.jpg',
                    icon: '/static/home/visa-icon1.png',
                    title: intl.get('Hongkongym.Slogan'),
                    link: '/services/turkeyym'
                },
                {
                    id: 'c9',
                    imgUrl: '/static/home/visa-img-3.jpg',
                    icon: '/static/home/visa-icon1.png',
                    title: intl.get('HomePage.CST3Children3'),
                    link: '/services/vanuatuym'
                }
            ]
        }
    ]);
    const [actIndex, setActIndex] = useState<number>(0);
    const [tagList] = useState([
        {
            text: intl.get('HomePage.Niue')
        },
        {
            text: intl.get('Menu.OverseasBankAccount')
        },
        {
            text: intl.get('HomePage.ImmigrationVisa')
        }
    ]);
    const tabRef = useRef<any>(null);

    // const formatLang = (lang: string) => {
    //     switch (lang) {
    //         case 'zh_CNS':
    //             return 'sc';
    //         case 'zh_CNT':
    //             return 'tc';
    //         case 'en_US':
    //             return 'en';
    //     }
    // };

    const listItemStyles = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '--item-box-act': `url('${file_cdn}/static/home/case-bg-act.svg') no-repeat right`,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '--item-box': `url('${file_cdn}/static/home/ash-icon.svg') no-repeat right`
    };

    return (
        <>
            <div className={style['case-share-container']} style={listItemStyles as React.CSSProperties}>
                <div className={style['case-title']}>
                    <div className={style['title-box']}>
                        <div className={style.tit}>{intl.get('HomePage.CaseSharing')}</div>
                        <div className={style['title-bg']}>Case Studies</div>
                    </div>
                    <div className={style['tit-desc']}>{intl.get('HomePage.CaseSharingDesc')}</div>
                </div>
                <div className={style['tab-list']}>
                    {tagList.map((item, index) => (
                        <div
                            key={item.text}
                            className={`${style['tab-item']} ${actIndex === index ? style.act : ''}`}
                            onClick={() => {
                                tabRef.current && tabRef.current.goTo(index);
                                setActIndex(index);
                            }}
                        >
                            <div className={style['item-box']}>
                                <div className={style['item-text']}>{item.text}</div>
                                <div className={style['item-line']} />
                            </div>
                        </div>
                    ))}
                </div>
                <LazyLoad>
                    <div className={style['tab-content']}>
                        <div className={style['content-list']}>
                            <Carousel dots={false} ref={tabRef} adaptiveHeight>
                                {tabContentList.map((item) => (
                                    <div key={item.id}>
                                        <div
                                            className={style['content-item-list']}
                                            style={item.topBg ? { backgroundImage: `url(${file_cdn}${item.topBg})` } : {}}
                                        >
                                            <div className={style['item-desc']} dangerouslySetInnerHTML={{ __html: item.text }} />
                                            <div className={style['content-child-list']}>
                                                {item.children.map((childItem) => (
                                                    <div key={childItem.id}>
                                                        <div className={`${style['child-item-img']} ${childItem.imgCls ? childItem.imgCls : ''}`}>
                                                            <img loading="lazy" src={`${file_cdn}${childItem.imgUrl}`} alt="" />
                                                        </div>
                                                        <div
                                                            className={style['icon-box']}
                                                            onClick={() => {
                                                                router.push(childItem.link);
                                                            }}
                                                        >
                                                            <div className={style['icon-box-flex']}>
                                                                <div
                                                                    className={`${style['child-icon']} ${childItem.cls ? childItem.cls : ''}`}
                                                                    style={{ backgroundImage: `url(${file_cdn}${childItem.icon})` }}
                                                                />
                                                                <p className={style['child-tit']}>{childItem.title}</p>
                                                            </div>
                                                            <RightOutlined />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Carousel>
                        </div>
                    </div>
                </LazyLoad>
            </div>
        </>
    );
};

export default CaseShare;
