import React, { useState } from 'react';
import intl from 'react-intl-universal';
import LazyLoad from 'react-lazyload';
import style from './index.module.less';

const PopularService: React.FC = () => {
    const file_cdn = '/oss-service';
    const [list] = useState([
        {
            id: '1',
            icon: '/static/home/service-icon1.svg',
            title: intl.get('HomePage.Niue'),
            desc: intl.get('HomePage.AgencyMajor'),
            iconBg: '/static/home/service-num1.svg'
        },
        {
            id: '2',
            icon: '/static/home/service-icon2.svg',
            title: intl.get('HomePage.AccountOpening'),
            desc: intl.get('HomePage.Satisfied'),
            iconBg: '/static/home/service-num2.svg'
        },
        {
            id: '3',
            icon: '/static/home/service-icon3.svg',
            title: intl.get('HomePage.FinancialServices'),
            desc: intl.get('HomePage.SeniorExperts'),
            iconBg: '/static/home/service-num3.svg'
        },
        {
            id: '4',
            icon: '/static/home/service-icon4.svg',
            title: intl.get('HomePage.ImmigrationVisa'),
            desc: intl.get('HomePage.PopularItems'),
            iconBg: '/static/home/service-num4.svg'
        }
    ]);
    return (
        <div className={style['service-contianer']}>
            <div className={style['title-box']}>
                <p className={style.title}>{intl.get('HomePage.PopularServices')}</p>
                <p className={style['tit-bg']}>Popular service</p>
            </div>
            <LazyLoad>
                <ul className={style['service-list']}>
                    {list.map((item) => (
                        <li key={item.id} className={style['item-box']}>
                            <div className={style['icon-box']}>
                                <img loading="lazy" src={`${file_cdn}${item.icon}`} alt="" />
                            </div>
                            <div className={style['text-box']}>
                                <p className={style['item-tit']} dangerouslySetInnerHTML={{ __html: item.title }} />
                                <p className={style['item-desc']} dangerouslySetInnerHTML={{ __html: item.desc }} />
                            </div>
                            <div className={style['item-bg']}>
                                <img loading="lazy" src={`${file_cdn}${item.iconBg}`} alt="" />
                            </div>
                        </li>
                    ))}
                </ul>
            </LazyLoad>
        </div>
    );
};

export default PopularService;
