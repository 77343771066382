import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import LazyLoad from 'react-lazyload';
import style from './index.module.less';

const mapImgObj = {
    en_US: '/static/home/en-map.png',
    zh_CNS: '/static/home/worl-map.png',
    zh_CNT: '/static/home/tc-map.png'
};

const WorldMap: React.FC = () => {
    const file_cdn = '/oss-service';
    const [langMapImg, setLangMapImg] = useState('');
    const {
        options: { currentLocale }
    } = intl as any;
    useEffect(() => {
        setLangMapImg(mapImgObj[currentLocale] || '/static/home/worl-map.png');
    }, [currentLocale]);
    return (
        <div className={style['world-map-container']}>
            <div className={style['case-title']}>
                <div className={style['title-box']}>
                    <div className={style.tit}>{intl.get('HomePage.GlobalLayout')}</div>
                    <div className={style['title-bg']}>The global layout</div>
                </div>
                <div className={style['tit-desc']}>{intl.get('HomePage.GlobalLayoutDesc')}</div>
            </div>
            <LazyLoad height={608}>
                <div className={style['map-container']} style={{ backgroundImage: `url(${file_cdn}${langMapImg})` }} />
            </LazyLoad>
        </div>
    );
};

export default WorldMap;
