export const bList = [
    {
        id: '1',
        icon: '/static/home/senior-icon1.svg',
        tit: 'HomePage.Year',
        desc: 'HomePage.IndustryServiceExperience'
    },
    {
        id: '2',
        icon: '/static/home/senior-icon2.svg',
        tit: '200+',
        desc: 'HomePage.SeniorConsultant',
        noTitleMts: true
    },
    {
        id: '3',
        icon: '/static/home/senior-icon3.svg',
        tit: '12',
        desc: 'HomePage.GlobalOffices',
        noTitleMts: true
    },
    {
        id: '4',
        icon: '/static/home/senior-icon4.svg',
        tit: '2000+',
        desc: 'HomePage.EnterpriseCustomers',
        noTitleMts: true
    }
];

export const seniorList = [
    {
        id: '1',
        img: '/static/home/pc/consultant_1.png',
        carousel_bg: '/static/home/pc/consultant_bg.jpg',
        qr_img: '/static/home/pc/qr_img.jpg',
        qr_img_en: '/static/home/pc/qr_img.png',
        tabs: ['HomePage.HKIdentityPlanning', 'HomePage.HKCompanyRegistration', 'HomePage.HKBankAccountOpening'],
        name: 'HomePage.ConsultantName',
        email: 'HomePage.ConsultantEmail',
        phone: 'HomePage.ConsultantPhone',
        desc: 'HomePage.ConsultantDesc'
    },
    {
        id: '2',
        img: '/static/home/pc/consultant_2.png',
        carousel_bg: '/static/home/pc/consultant_bg2.jpg',
        qr_img: '/static/home/pc/qr_img2.jpg',
        qr_img_en: '/static/home/pc/qr_img2.png',
        tabs: ['HomePage.OverseasBankAccountOpening', 'HomePage.GlobalCompanyRegistration', 'HomePage.OverseasImmigrationVisa'],
        name: 'HomePage.ConsultantName2',
        email: 'HomePage.ConsultantEmail2',
        phone: 'HomePage.ConsultantPhone2',
        desc: 'HomePage.ConsultantDesc2'
    },
    {
        id: '3',
        img: '/static/home/pc/consultant_3.png',
        carousel_bg: '/static/home/pc/consultant_bg3.jpg',
        qr_img: '/static/home/pc/qr_img3.jpg',
        qr_img_en: '/static/home/pc/qr_img3.png',
        tabs: ['HomePage.OverseasImmigrationVisa', 'HomePage.BVICompanyRegistration', 'HomePage.ImmigrationPlanPlanning'],
        name: 'HomePage.ConsultantName3',
        email: 'HomePage.ConsultantEmail3',
        phone: 'HomePage.ConsultantPhone3',
        desc: 'HomePage.ConsultantDesc3'
    },
    {
        id: '4',
        img: '/static/home/pc/consultant_4.png',
        carousel_bg: '/static/home/pc/consultant_bg4.jpg',
        qr_img: '/static/home/pc/qr_img4.jpg',
        qr_img_en: '/static/home/pc/qr_img4.png',
        tabs: ['HomePage.OverseasImmigrationVisa', 'HomePage.HKCompanyRegistration', 'HomePage.OverseasBankAccountOpening'],
        name: 'HomePage.ConsultantName4',
        email: 'HomePage.ConsultantEmail4',
        phone: 'HomePage.ConsultantPhone4',
        desc: 'HomePage.ConsultantDesc4'
    }
];

// ================================ m ====================================
export const mBList = [
    {
        id: '1',
        icon: '/static/home/senior-icon1.svg',
        tit: 'HomePage.Year',
        desc: 'HomePage.IndustryServiceExperience'
    },
    {
        id: '2',
        icon: '/static/home/senior-icon4.svg',
        tit: '2000+',
        desc: 'HomePage.EnterpriseCustomers',
        noTitleMts: true
    },
    {
        id: '3',
        icon: '/static/home/senior-icon2.svg',
        tit: '200+',
        desc: 'HomePage.SeniorConsultant',
        noTitleMts: true
    },
    {
        id: '4',
        icon: '/static/home/senior-icon3.svg',
        tit: '12',
        desc: 'HomePage.GlobalOffices',
        noTitleMts: true
    }
];

export const mSeniorList = [
    {
        id: '1',
        img: '/static/home/m/consultant_1.png',
        qr_img: '/static/home/m/qr_img1.jpg',
        qr_img_en: '/static/home/m/qr_img1.png',
        tabs: ['HomePage.HKIdentityPlanning', 'HomePage.HKCompanyRegistration', 'HomePage.HKBankAccountOpening'],
        name: 'HomePage.ConsultantName',
        email: 'HomePage.ConsultantEmail',
        phone: 'HomePage.ConsultantPhone',
        desc: 'HomePage.ConsultantDesc'
    },
    {
        id: '2',
        img: '/static/home/m/consultant_2.png',
        qr_img: '/static/home/m/qr_img2.jpg',
        qr_img_en: '/static/home/m/qr_img2.png',
        tabs: ['HomePage.OverseasBankAccountOpening', 'HomePage.GlobalCompanyRegistration', 'HomePage.OverseasImmigrationVisa'],
        name: 'HomePage.ConsultantName2',
        email: 'HomePage.ConsultantEmail2',
        phone: 'HomePage.ConsultantPhone2',
        desc: 'HomePage.ConsultantDesc2'
    },
    {
        id: '3',
        img: '/static/home/m/consultant_3.png',
        qr_img: '/static/home/m/qr_img3.jpg',
        qr_img_en: '/static/home/m/qr_img3.png',
        tabs: ['HomePage.OverseasImmigrationVisa', 'HomePage.BVICompanyRegistration', 'HomePage.ImmigrationPlanPlanning'],
        name: 'HomePage.ConsultantName3',
        email: 'HomePage.ConsultantEmail3',
        phone: 'HomePage.ConsultantPhone3',
        desc: 'HomePage.ConsultantDesc3'
    },
    {
        id: '4',
        img: '/static/home/m/consultant_4.png',
        qr_img: '/static/home/m/qr_img4.jpg',
        qr_img_en: '/static/home/m/qr_img4.png',
        tabs: ['HomePage.OverseasImmigrationVisa', 'HomePage.HKCompanyRegistration', 'HomePage.OverseasBankAccountOpening'],
        name: 'HomePage.ConsultantName4',
        email: 'HomePage.ConsultantEmail4',
        phone: 'HomePage.ConsultantPhone4',
        desc: 'HomePage.ConsultantDesc4'
    }
];
