import classNames from 'classnames';
import intl from 'react-intl-universal';
import LazyLoad from 'react-lazyload';
import { Carousel } from 'antd';
import React, { useEffect, useRef } from 'react';
import style from './index.module.less';

interface Iprops {
    list: {
        id: string;
        img: string;
        qr_img: string;
        qr_img_en: string;
        tabs: string[];
        name: string;
        email: string;
        phone: string;
        desc: string;
    }[];
    actIndex: number;
    handleChange: (index: number) => void;
}

const MCarousel: React.FC<Iprops> = (props: Iprops) => {
    const file_cdn = '/oss-service';
    const { list, actIndex, handleChange } = props;
    const carouselRef = useRef(null);
    const {
        options: { currentLocale }
    } = intl as any;

    const isEnCur = currentLocale === 'en_US';

    useEffect(() => {
        carouselRef.current?.goTo(actIndex);
    }, [actIndex]);

    return (
        <div className={classNames(style['carousel-con'], isEnCur ? style['carousel-con-en'] : null)}>
            <LazyLoad>
                <Carousel adaptiveHeight dots ref={carouselRef} afterChange={(index) => handleChange(index)}>
                    {list.map((item) => (
                        <div className={style['carousel-item']} key={item.id}>
                            <div className={style['header-icon']}>
                                <img src={`${file_cdn}/static/home/m/consultant_icon.png`} alt="" />
                            </div>
                            <div className={style['carousel-item-img']}>
                                <div className={style['consultent-name']}>{intl.get(item.name).d(item.name)}</div>
                                <img src={`${file_cdn}/static/home/m/detail_bg.png`} alt="" />
                            </div>
                            <div className={style['carousel-item-content']}>
                                <div className={style['carousel-item-header']}>
                                    <div className={style.qr_img}>
                                        <img src={`${file_cdn}${isEnCur ? item.qr_img_en : item.qr_img}`} alt="" />
                                    </div>
                                    <ul>
                                        {item.tabs.map((tab) => (
                                            <li key={tab}>
                                                <span className={style.tab}>{intl.get(tab).d(tab)}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className={style['carousel-item-footer']}>
                                    <ul>
                                        <li>
                                            <span>{intl.get('HomePage.EmailLabel').d('邮箱：')}</span>
                                            <span>{intl.get(item.email).d(item.email)}</span>
                                        </li>
                                        <li>
                                            <span>{isEnCur ? 'WhatsApp：' : intl.get('HomePage.PhoneLabel').d('手机号：')}</span>
                                            <span>{isEnCur ? `+86 ${intl.get(item.phone).d(item.phone)}` : intl.get(item.phone).d(item.phone)}</span>
                                        </li>
                                        <li>
                                            <span>{intl.get('HomePage.DescriptLabel').d('个人简介：')}</span>
                                            <span>{intl.get(item.desc).d(item.desc)}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    ))}
                </Carousel>
            </LazyLoad>
        </div>
    );
};

export default MCarousel;
