import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { Modal } from 'antd';
import LazyLoad from 'react-lazyload';
import style from './index.module.less';

const Qualification: React.FC = () => {
    const file_cdn = '/oss-service';
    const [quaList] = useState([
        {
            id: '1',
            text: intl.get('HomePage.ITitle1'),
            imgUrl: '/static/home/qualification-img1.jpg'
        },
        {
            id: '2',
            text: intl.get('HomePage.ITitle2'),
            imgUrl: '/static/home/qualification-img2.jpg'
        },
        {
            id: '3',
            text: intl.get('HomePage.ITitle3'),
            imgUrl: '/static/home/qualification-img3.jpg'
        },
        {
            id: '4',
            text: intl.get('HomePage.ITitle4'),
            imgUrl: '/static/home/qualification-img4.jpg'
        }
    ]);
    const [visible, setVisible] = useState(false);
    const [showImgUrl, setShowImgUrl] = useState<string>('');
    return (
        <div className={style['qualification-container']}>
            <div className={style['left-con']}>
                <div className={style['left-con-pre']}>
                    <div className={style['title-box']}>
                        <p className={style.title}>{intl.get('HomePage.Intelligence')}</p>
                        <p className={style['tit-bg']}>Company Qualification</p>
                        <p className={style['tit-desc']}>{intl.get('HomePage.IntelligenceDesc')}</p>
                    </div>
                    <LazyLoad height={632}>
                        <div className={style['qualification-img-con']}>
                            <ul className={style['qualification-list']}>
                                {quaList.map((item) => (
                                    <li key={item.id} className={style['qualification-item']}>
                                        <img
                                            loading="lazy"
                                            onClick={() => {
                                                setVisible(true);
                                                setShowImgUrl(item.imgUrl);
                                            }}
                                            className={style['item-img']}
                                            src={`${file_cdn}${item.imgUrl}`}
                                            alt=""
                                        />
                                        <p className={style['item-text']}>{item.text}</p>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </LazyLoad>
                </div>
                <div className={style['bg-img-box']} style={{ backgroundImage: `url('${file_cdn}/static/home/symbol-bg1.svg')` }} />
            </div>
            <Modal
                centered
                closable={false}
                maskClosable
                mask={false}
                onCancel={() => {
                    setVisible(false);
                }}
                bodyStyle={{
                    padding: 0
                }}
                width={530}
                footer={null}
                open={visible}
            >
                <img loading="lazy" className={style['modal-img']} src={`${file_cdn}${showImgUrl}`} alt="" />
            </Modal>
        </div>
    );
};

export default Qualification;
