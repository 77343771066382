import classNames from 'classnames';
import intl from 'react-intl-universal';
import LazyLoad from 'react-lazyload';
import { Titlecom } from '@components/Common';
import { Carousel } from 'antd';
import React, { useRef, useState } from 'react';
import style from './index.module.less';

interface Iprops {
    list: {
        id: string;
        img: string;
        carousel_bg: string;
        qr_img: string;
        qr_img_en: string;
        tabs: string[];
        name: string;
        email: string;
        phone: string;
        desc: string;
    }[];
    isCenter?: boolean;
}

const SeniorTeam: React.FC<Iprops> = (props: Iprops) => {
    const file_cdn = '/oss-service';
    const { isCenter, list } = props;
    const carouselRef = useRef(null);
    const [actIndex, setActIndex] = useState<number>(0);
    const {
        options: { currentLocale }
    } = intl as any;
    const isEnCur = currentLocale === 'en_US';

    const handleChange = (index: number) => {
        setActIndex(index);
        carouselRef.current.goTo(index);
    };

    return (
        <div className={style['senior-container']}>
            <Titlecom
                title={intl.get('HomePage.SeniorTeamTit').d('资深团队')}
                type="center"
                label={intl.get('HomePage.SeniorTeamDes').d('- 为全球客户提供卓越的专业顾问服务 -')}
                descContent="Senior Team"
            />
            <LazyLoad>
                <div className={style['senior-list-con']}>
                    <ul className={classNames(style['senior-list'], isCenter && style.center)}>
                        {list.map((item, index) => (
                            <li className={style['item-box']} key={item.id}>
                                <img className={style['item-icon']} src={`${file_cdn}/static/home/pc/consultant_icon.png`} alt="" />
                                <img className={style['item-img']} src={`${file_cdn}${item.img}`} alt="" />
                                <div className={classNames(style['item-name'], actIndex === index ? style['name-act'] : null)}>
                                    {intl.get(item.name).d(item.name)}
                                </div>
                                <div
                                    className={style['hot-area']}
                                    onClick={() => {
                                        handleChange(index);
                                    }}
                                />
                            </li>
                        ))}
                    </ul>
                </div>
                <div className={style['carousel-con']}>
                    <Carousel
                        adaptiveHeight
                        dots
                        ref={carouselRef}
                        afterChange={(index) => {
                            handleChange(index);
                        }}
                    >
                        {list.map((item) => (
                            <div className={style['carousel-item']} key={item.id}>
                                <div className={style['carousel-item-img']}>
                                    <img src={`${file_cdn}${item.carousel_bg}`} alt="" />
                                </div>
                                <div className={style['carousel-item-content']}>
                                    <div className={style['carousel-item-header']}>
                                        <div className={style['header-right']}>
                                            <div className={classNames(style['header-icon'], isEnCur ? style['header-icon-en'] : null)}>
                                                <img src={`${file_cdn}/static/home/pc/consultant_icon.png`} alt="" />
                                            </div>
                                            <ul>
                                                {item.tabs.map((tab) => (
                                                    <li key={tab}>{intl.get(tab).d(tab)}</li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div className={classNames(style['header-left'], isEnCur ? style['header-left-en'] : null)}>
                                            <img src={`${file_cdn}${isEnCur ? item.qr_img_en : item.qr_img}`} alt="" />
                                        </div>
                                    </div>
                                    <div className={classNames(style['carousel-item-footer'], isEnCur ? style['carousel-item-footer-en'] : null)}>
                                        <ul>
                                            <li>
                                                <span>{intl.get('HomePage.EmailLabel').d('邮箱：')}</span>
                                                <span>{intl.get(item.email).d(item.email)}</span>
                                            </li>
                                            <li>
                                                <span>{isEnCur ? 'WhatsApp：' : intl.get('HomePage.PhoneLabel').d('手机号：')}</span>
                                                <span>
                                                    {isEnCur ? `+86 ${intl.get(item.phone).d(item.phone)}` : intl.get(item.phone).d(item.phone)}
                                                </span>
                                            </li>
                                            <li>
                                                <span>{intl.get('HomePage.DescriptLabel').d('个人简介：')}</span>
                                                <span>{intl.get(item.desc).d(item.desc)}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className={style['consultent-name']}>{intl.get(item.name).d(item.name)}</div>
                            </div>
                        ))}
                    </Carousel>
                </div>
            </LazyLoad>
        </div>
    );
};

SeniorTeam.defaultProps = {
    isCenter: false
};

export default SeniorTeam;
