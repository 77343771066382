import style from './style.module.less';
import HomeBanner from './component/Banner';
import PopularService from './component/PopularService';
import Senior from './component/Senior';
import Advantage from './component/Advantage';
import Qualification from './component/Qualification';
import CaseShare from './component/CaseShare';
import WorldMap from './component/WorldMap';
import MSenior from './m-component/MSenior';
import MHomeBanner from './m-component/MBanner';
import MPopularService from './m-component/MPopularService';
import MAdvantage from './m-component/MAdvantage';
import MQualification from './m-component/MQualification';
import MCaseShare from './m-component/MCaseShare';
import MWorldMap from './m-component/MWorldMap';
import { bList, mBList, mSeniorList, seniorList } from './data';
import SeniorTeam from './component/SeniorTeam';
import MSeniorTeam from './component/MSeniorTeam';

const Homepage: React.FC<any> = ({ isMobile }: any) => (
    <div className={style.homePage}>
        {!isMobile ? (
            <>
                <HomeBanner />
                <PopularService />
                <Advantage />
                <Qualification />
                <SeniorTeam list={seniorList} />
                <CaseShare />
                <WorldMap />
                <Senior list={bList} />
            </>
        ) : (
            <>
                <MHomeBanner />
                <MPopularService />
                <MAdvantage />
                <MQualification />
                <MSeniorTeam list={mSeniorList} />
                <MCaseShare />
                <MWorldMap />
                <MSenior list={mBList} />
            </>
        )}
    </div>
);

export default Homepage;
