import React, { useState } from 'react';
import intl from 'react-intl-universal';
import LazyLoad from 'react-lazyload';
import style from './index.module.less';

const Advantage: React.FC = () => {
    const file_cdn = '/oss-service';
    const [advantageList] = useState([
        {
            id: '1',
            title: intl.get('HomePage.SATitle1'),
            desc: intl.get('HomePage.SADesc1')
        },
        {
            id: '2',
            title: intl.get('HomePage.SATitle2'),
            desc: intl.get('HomePage.SADesc2')
        },
        {
            id: '3',
            title: intl.get('HomePage.SATitle3'),
            desc: intl.get('HomePage.SADesc3')
        },
        {
            id: '4',
            title: intl.get('HomePage.SATitle4'),
            desc: intl.get('HomePage.SADesc4')
        }
    ]);
    return (
        <>
            <LazyLoad>
                <div className={style['advantage-container']}>
                    <div className={style['right-content']} style={{ backgroundImage: `url('${file_cdn}/static/home/symbol-bg1.svg')` }}>
                        <div className={style['r-img1']} style={{ backgroundImage: `url('${file_cdn}/static/home/service-advantage2.jpg')` }} />
                        <div className={style['r-img2']}>
                            <img loading="lazy" src={`${file_cdn}/static/home/service-advantage1.jpg`} alt="" />
                            <img loading="lazy" src={`${file_cdn}/static/home/service-advantage3.jpg`} alt="" />
                        </div>
                    </div>
                    <div className={style['left-content']}>
                        <div className={style['title-box']}>
                            <p className={style.title}>{intl.get('HomePage.ServiceAdvantages')}</p>
                            <p className={style['tit-bg']}>Service advantages</p>
                        </div>
                        <ul className={style['advantage-list']}>
                            {advantageList.map((item) => (
                                <li key={item.id} className={style['advantage-item']}>
                                    <p className={style['item-tit']}>{item.title}</p>
                                    <div className={style['item-line']} />
                                    <p className={style['item-desc']}>{item.desc}</p>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </LazyLoad>
        </>
    );
};

export default Advantage;
