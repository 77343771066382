import classNames from 'classnames';
import intl from 'react-intl-universal';
import LazyLoad from 'react-lazyload';
import { Carousel } from 'antd';
import React, { useRef, useState } from 'react';
import { MTitlecom } from '@components/MCommon';
import MCarousel from './MCarousel';
import style from './index.module.less';

interface Iprops {
    list: {
        id: string;
        img: string;
        qr_img: string;
        qr_img_en: string;
        tabs: string[];
        name: string;
        email: string;
        phone: string;
        desc: string;
    }[];
}

const MSeniorTeam: React.FC<Iprops> = (props: Iprops) => {
    const file_cdn = '/oss-service';
    const { list } = props;
    const [actIndex, setActIndex] = useState<number>(0);
    const sliderRef = useRef<any>(null);

    const nList = [
        ...list,
        {
            id: '5',
            img: '',
            qr_img: '',
            tabs: [],
            name: '',
            email: '',
            phone: '',
            desc: ''
        }
    ];

    const handleChange = (index: number) => {
        sliderRef.current.goTo(index);
    };
    return (
        <div className={style['senior-container']}>
            <MTitlecom
                title={intl.get('HomePage.SeniorTeamTit').d('资深团队')}
                type="center"
                label={intl.get('HomePage.SeniorTeamDes').d('- 为全球客户提供卓越的专业顾问服务 -')}
                descContent="Senior Team"
            />
            <LazyLoad>
                <div className={style['left-con']}>
                    <div className={style['qualification-img-con']}>
                        <div className={style['carousel-box']}>
                            <img
                                className={style['prev-img']}
                                src={`${file_cdn}/static/home/m/prev-icon.svg`}
                                alt=""
                                onClick={() => {
                                    sliderRef.current && sliderRef.current.prev();
                                }}
                            />
                            <Carousel
                                slidesToShow={1.5}
                                ref={sliderRef}
                                infinite
                                beforeChange={(oldIdx, newIdex) => {
                                    const index = Math.ceil(newIdex);
                                    const oldIndex = Math.ceil(oldIdx);
                                    if (oldIndex === 0 && index === 4) {
                                        sliderRef.current.goTo(3);
                                        setActIndex(3);
                                    } else if (oldIdx === 3 && index === 4) {
                                        sliderRef.current.goTo(0);
                                        setActIndex(0);
                                    } else {
                                        setActIndex(index);
                                    }
                                }}
                                className={`${style['qualification-list']} qualification-list-container ${style['senior-list-con']}`}
                                variableWidth
                                dots={false}
                            >
                                {nList.map((item, index) =>
                                    index !== 4 ? (
                                        <div className={classNames(style['item-box'], index % 2 !== 0 ? style['item-odd'] : null)} key={item.id}>
                                            <img className={style['item-icon']} src={`${file_cdn}/static/home/m/consultant_icon.png`} alt="" />
                                            <img className={style['item-img']} src={`${file_cdn}${item.img}`} alt="" />
                                            <div className={classNames(style['item-name'], index === actIndex ? style['item-active'] : null)}>
                                                {item.name && intl.get(item.name).d(item.name)}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className={style['item-end']} key={item.id} />
                                    )
                                )}
                            </Carousel>
                            <img
                                src={`${file_cdn}/static/home/m/next-icon.svg`}
                                className={style['next-img']}
                                alt=""
                                onClick={() => {
                                    sliderRef.current && sliderRef.current.next();
                                }}
                            />
                        </div>
                    </div>
                </div>

                <MCarousel list={list} actIndex={actIndex} handleChange={handleChange} />
            </LazyLoad>
        </div>
    );
};

export default MSeniorTeam;
